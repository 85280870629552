.buttonHeading {
    font-size: 1em;
    background-color:  #0070c0;
    color: #fff;
    border: 1px solid #bcbaba;
    border-radius: 100px;
    padding: 0em 1em;

}
.walletText {
    font-size: 1em;
    color: #fff;;
    margin-right: 10px;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
}
.buttonDisconnect {
    font-size: 1.2em;
    background-color: #242323;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 100px;

}

.text-white {
    
    color: #fff !important;
}

.connectbutton { flex-grow: 1;
text-align: end;
margin-right: 4%;
}

@media screen and (max-width: 1200px) {
    .connectbutton { margin-left: 0%;
    margin-top: 5%;
    text-align: start;}}


.languagebuttonen {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    font-family: var(--heading-font);
    font-size: small;
    justify-content: flex-start;
    margin-right: 0;
    padding-right: 0;

    

    
}
.languagebuttonen:focus {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    font-family: var(--heading-font);
    font-size: small;
    margin-right: 0;
    padding-right: 0;
    outline: none;   
    color:#0070c0;
}
.languagebuttontr {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    justify-content: flex-start;
    font-family: var(--heading-font);
    font-size: small;

    
}
.languagebuttontr:focus {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    
    font-family: var(--heading-font);
    font-size: small;
    outline: none;
    color:#0070c0;

    
}