.helpPeopleText {
    font-size: 2.2em;
}
.mbllanguage{flex-grow: 1;
text-align: center;}

.mbllanguagebuttonen {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    font-family: var(--heading-font);
    font-size: small;
    justify-content: flex-start;
    margin-right: 0;
    padding-right: 0;
    color:white;
    

    
}
.mbllanguagebuttonen:focus {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    font-family: var(--heading-font);
    font-size: small;
    margin-right: 0;
    padding-right: 0;
    outline: none;   
    color:#0070c0;
}
.mbllanguagebuttontr {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    justify-content: flex-start;
    font-family: var(--heading-font);
    font-size: small;
    color:white;

    
}
.mbllanguagebuttontr:focus {
    border: none;
    background: none;
    margin-left: 0;
    padding-left: 0;
    
    font-family: var(--heading-font);
    font-size: small;
    outline: none;
    color:#0070c0;

    
}